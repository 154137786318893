import React from 'react';
import styled from 'styled-components';
import { breakpoints, colors } from 'styles/variables';
import Piter from '../../../assets/piter.png';
import Piterrun from '../../../assets/piterrun.png';
import { P } from 'styles/tags';

const SlideGreen = () => {
  return (
    <Slide>
      <Name>
        <Char>
          <img src={Piterrun} />
        </Char>
        <img src={Piter} />
      </Name>

      <P>
        Великий император Петр I&nbsp;любознателен и&nbsp;пытлив:
        он&nbsp;стремится постоянно узнавать что-то новое и&nbsp;побуждает
        к&nbsp;этому других. Стоит Петру придумать новую идею, он&nbsp;сразу
        возьмется за&nbsp;ее&nbsp;воплощение.
      </P>
    </Slide>
  );
};

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
  gap: 150px;
  padding: 250px 0 50px 0;
  background-color: ${colors.green};
  P {
    text-align: center;
    color: ${colors.white};
    max-width: 650px;
    @media ${breakpoints.laptop} {
      max-width: 46.429vw;
    }
    @media ${breakpoints.mobile} {
      max-width: 90.429vw;
    }
  }
  @media ${breakpoints.laptop} {
    height: 42.857vw;
    gap: 10.714vw;
    padding: 17.857vw 0 3.571vw 0;
  }
  @media ${breakpoints.mobile} {
    height: 125vw;
    gap: 46.875vw;
    padding: 7.813vw 0 21.875vw 0;
  }
`;

const Name = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 650px;
  img {
    width: 100%;
  }
  @media ${breakpoints.laptop} {
    width: 46.429vw;
  }
  @media ${breakpoints.mobile} {
    width: 60.25vw;
    height: auto;
    margin-bottom: auto;
    top: 3.5vw;
  }
`;

const Char = styled.div`
  position: absolute;
  top: -300px;
  left: 0px;
  width: 100%;
  img {
    width: 100%;
    @media ${breakpoints.mobile} {
      width: 190%;
    }
  }
  @media ${breakpoints.laptop} {
    top: -21.429vw;
    left: 0vw;
  }
  @media ${breakpoints.mobile} {
    top: -8vw;
    left: -32vw;
  }
`;

export default SlideGreen;
