import React from 'react';

const SvgSelector = ({ name }) => {
  switch (name) {
    //------------------------------------------Преимущества------------------------------------------\\
    case 'tg':
      return (
        <svg
          width="45"
          height="45"
          viewBox="0 0 45 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22 0.703125C9.84677 0.703125 0 10.459 0 22.5C0 34.541 9.84677 44.2969 22 44.2969C34.1532 44.2969 44 34.541 44 22.5C44 10.459 34.1532 0.703125 22 0.703125ZM32.8048 15.6357L29.1944 32.4932C28.9282 33.6885 28.2097 33.9785 27.2073 33.416L21.7073 29.3994L19.0548 31.9307C18.7621 32.2207 18.5137 32.4668 17.946 32.4668L18.3363 26.9209L28.529 17.7979C28.9726 17.4111 28.4314 17.1914 27.846 17.5781L15.2492 25.4355L9.82016 23.7568C8.64032 23.3877 8.61371 22.5879 10.0685 22.0254L31.279 13.9219C32.2637 13.5703 33.1242 14.1592 32.8048 15.6357Z"
            fill="#719FFF"
          />
        </svg>
      );
    case 'vk':
      return (
        <svg
          width="45"
          height="45"
          viewBox="0 0 45 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.8819 3.82818C0 6.71905 0 11.3722 0 20.6775V22.3225C0 31.6279 0 36.281 2.8819 39.1717C5.76398 42.0625 10.403 42.0625 19.68 42.0625H21.32C30.5971 42.0625 35.2361 42.0625 38.118 39.1717C41 36.281 41 31.6279 41 22.3225V20.6775C41 11.3721 41 6.71896 38.118 3.82818C35.2361 0.9375 30.5971 0.9375 21.32 0.9375H19.68C10.4029 0.9375 5.76389 0.9375 2.8819 3.82818ZM6.91875 13.4465H11.5996C11.7534 21.2945 15.2041 24.6187 17.9375 25.3042V13.4464H22.345V20.2148C25.0441 19.9236 27.88 16.8392 28.8366 13.4464H33.2441C32.8835 15.206 32.1649 16.872 31.1332 18.3403C30.1014 19.8086 28.7788 21.0476 27.2479 21.9798C28.9572 22.831 30.4669 24.0364 31.6772 25.5163C32.8876 26.9961 33.771 28.7169 34.2691 30.5647H29.4175C28.9705 28.9596 28.0608 27.5227 26.8025 26.4344C25.5443 25.3461 23.9935 24.6548 22.345 24.4473V30.5647H21.8154C12.4709 30.5647 7.14086 24.1389 6.91875 13.4464"
            fill="#719FFF"
          />
        </svg>
      );
    case 'tt':
      return (
        <svg
          width="45"
          height="45"
          viewBox="0 0 45 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.9844 6.08292C42.9844 2.73206 40.2679 0.015625 36.9171 0.015625H6.08292C2.73206 0.015625 0.015625 2.73206 0.015625 6.08292V36.9171C0.015625 40.268 2.73206 42.9844 6.08292 42.9844H36.9171C40.2679 42.9844 42.9844 40.268 42.9844 36.9171V6.08292ZM35.613 18.7562C32.8407 18.7872 30.2575 17.9491 27.9894 16.3969V27.2934C27.9894 32.1363 24.6744 36.2965 19.9867 37.3829C13.5914 38.842 8.17759 34.2164 7.49477 28.6906C6.78073 23.1646 10.305 18.3217 15.7067 17.2351C16.7622 17.0178 18.3498 17.0178 19.1688 17.2041V23.0404C18.9168 22.9784 18.6808 22.9163 18.4325 22.8853C16.3215 22.5127 14.2768 23.5682 13.4386 25.4929C12.6005 27.4178 13.1615 29.6529 14.8689 30.9569C16.2969 32.0744 17.8813 32.2296 19.4956 31.4845C21.11 30.7706 21.9775 29.4667 22.1637 27.6972C22.1947 27.449 22.193 27.1695 22.193 26.8901V5.93489C22.193 5.34509 22.1977 5.37082 22.7875 5.37082H27.4131C27.7547 5.37082 27.8789 5.41997 27.9099 5.82376C28.1581 9.42492 30.8923 12.4918 34.4002 12.9575C34.7728 13.0196 35.172 13.0442 35.613 13.0752V18.7562Z"
            fill="#719FFF"
          />
        </svg>
      );
    case 'yt':
      return (
        <svg
          width="57"
          height="45"
          viewBox="0 0 57 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.5006 0.166748C30.8875 0.166748 33.3358 0.228165 35.7087 0.328665L38.5116 0.462665L41.1944 0.62179L43.7069 0.792081L46.0016 0.970748C48.4923 1.16028 50.8362 2.22263 52.6205 3.97068C54.4048 5.71873 55.5151 8.04035 55.7557 10.5266L55.8674 11.7131L56.0767 14.2535C56.2721 16.886 56.4173 19.7559 56.4173 22.5001C56.4173 25.2443 56.2721 28.1141 56.0767 30.7467L55.8674 33.2871L55.7557 34.4735C55.515 36.9603 54.4043 39.2822 52.6195 41.0303C50.8346 42.7784 48.49 43.8405 45.9988 44.0294L43.7096 44.2053L41.1972 44.3784L38.5116 44.5375L35.7087 44.6715C33.3074 44.7755 30.9042 44.8295 28.5006 44.8334C26.0971 44.8295 23.6939 44.7755 21.2926 44.6715L18.4897 44.5375L15.8069 44.3784L13.2944 44.2053L10.9997 44.0294C8.50901 43.8399 6.16508 42.7775 4.38077 41.0295C2.59646 39.2814 1.48623 36.9598 1.24561 34.4735L1.13394 33.2871L0.924568 30.7467C0.711934 28.0028 0.598333 25.2521 0.583984 22.5001C0.583984 19.7559 0.729151 16.886 0.924568 14.2535L1.13394 11.7131L1.24561 10.5266C1.48613 8.04079 2.59597 5.71952 4.37971 3.97153C6.16345 2.22353 8.50671 1.16091 10.9969 0.970748L13.2889 0.792081L15.8014 0.62179L18.4869 0.462665L21.2898 0.328665C23.692 0.224611 26.0962 0.170627 28.5006 0.166748ZM22.9173 15.7303V29.2699C22.9173 30.5596 24.3131 31.3636 25.4298 30.7215L37.1548 23.9518C37.41 23.8049 37.6219 23.5934 37.7692 23.3385C37.9166 23.0837 37.9942 22.7945 37.9942 22.5001C37.9942 22.2057 37.9166 21.9165 37.7692 21.6617C37.6219 21.4068 37.41 21.1953 37.1548 21.0484L25.4298 14.2814C25.1751 14.1344 24.8862 14.057 24.592 14.057C24.2979 14.0571 24.009 14.1345 23.7544 14.2817C23.4997 14.4288 23.2883 14.6404 23.1413 14.8952C22.9944 15.15 22.9171 15.439 22.9173 15.7331V15.7303Z"
            fill="#719FFF"
          />
        </svg>
      );

    case 'tgw':
      return (
        <svg
          width="45"
          height="45"
          viewBox="0 0 45 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22 0.703125C9.84677 0.703125 0 10.459 0 22.5C0 34.541 9.84677 44.2969 22 44.2969C34.1532 44.2969 44 34.541 44 22.5C44 10.459 34.1532 0.703125 22 0.703125ZM32.8048 15.6357L29.1944 32.4932C28.9282 33.6885 28.2097 33.9785 27.2073 33.416L21.7073 29.3994L19.0548 31.9307C18.7621 32.2207 18.5137 32.4668 17.946 32.4668L18.3363 26.9209L28.529 17.7979C28.9726 17.4111 28.4314 17.1914 27.846 17.5781L15.2492 25.4355L9.82016 23.7568C8.64032 23.3877 8.61371 22.5879 10.0685 22.0254L31.279 13.9219C32.2637 13.5703 33.1242 14.1592 32.8048 15.6357Z"
            fill="#fff"
          />
        </svg>
      );
    case 'vkw':
      return (
        <svg
          width="45"
          height="45"
          viewBox="0 0 45 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.8819 3.82818C0 6.71905 0 11.3722 0 20.6775V22.3225C0 31.6279 0 36.281 2.8819 39.1717C5.76398 42.0625 10.403 42.0625 19.68 42.0625H21.32C30.5971 42.0625 35.2361 42.0625 38.118 39.1717C41 36.281 41 31.6279 41 22.3225V20.6775C41 11.3721 41 6.71896 38.118 3.82818C35.2361 0.9375 30.5971 0.9375 21.32 0.9375H19.68C10.4029 0.9375 5.76389 0.9375 2.8819 3.82818ZM6.91875 13.4465H11.5996C11.7534 21.2945 15.2041 24.6187 17.9375 25.3042V13.4464H22.345V20.2148C25.0441 19.9236 27.88 16.8392 28.8366 13.4464H33.2441C32.8835 15.206 32.1649 16.872 31.1332 18.3403C30.1014 19.8086 28.7788 21.0476 27.2479 21.9798C28.9572 22.831 30.4669 24.0364 31.6772 25.5163C32.8876 26.9961 33.771 28.7169 34.2691 30.5647H29.4175C28.9705 28.9596 28.0608 27.5227 26.8025 26.4344C25.5443 25.3461 23.9935 24.6548 22.345 24.4473V30.5647H21.8154C12.4709 30.5647 7.14086 24.1389 6.91875 13.4464"
            fill="#fff"
          />
        </svg>
      );
    case 'ttw':
      return (
        <svg
          width="45"
          height="45"
          viewBox="0 0 45 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.9844 6.08292C42.9844 2.73206 40.2679 0.015625 36.9171 0.015625H6.08292C2.73206 0.015625 0.015625 2.73206 0.015625 6.08292V36.9171C0.015625 40.268 2.73206 42.9844 6.08292 42.9844H36.9171C40.2679 42.9844 42.9844 40.268 42.9844 36.9171V6.08292ZM35.613 18.7562C32.8407 18.7872 30.2575 17.9491 27.9894 16.3969V27.2934C27.9894 32.1363 24.6744 36.2965 19.9867 37.3829C13.5914 38.842 8.17759 34.2164 7.49477 28.6906C6.78073 23.1646 10.305 18.3217 15.7067 17.2351C16.7622 17.0178 18.3498 17.0178 19.1688 17.2041V23.0404C18.9168 22.9784 18.6808 22.9163 18.4325 22.8853C16.3215 22.5127 14.2768 23.5682 13.4386 25.4929C12.6005 27.4178 13.1615 29.6529 14.8689 30.9569C16.2969 32.0744 17.8813 32.2296 19.4956 31.4845C21.11 30.7706 21.9775 29.4667 22.1637 27.6972C22.1947 27.449 22.193 27.1695 22.193 26.8901V5.93489C22.193 5.34509 22.1977 5.37082 22.7875 5.37082H27.4131C27.7547 5.37082 27.8789 5.41997 27.9099 5.82376C28.1581 9.42492 30.8923 12.4918 34.4002 12.9575C34.7728 13.0196 35.172 13.0442 35.613 13.0752V18.7562Z"
            fill="#fff"
          />
        </svg>
      );
    case 'ytw':
      return (
        <svg
          width="57"
          height="45"
          viewBox="0 0 57 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.5006 0.166748C30.8875 0.166748 33.3358 0.228165 35.7087 0.328665L38.5116 0.462665L41.1944 0.62179L43.7069 0.792081L46.0016 0.970748C48.4923 1.16028 50.8362 2.22263 52.6205 3.97068C54.4048 5.71873 55.5151 8.04035 55.7557 10.5266L55.8674 11.7131L56.0767 14.2535C56.2721 16.886 56.4173 19.7559 56.4173 22.5001C56.4173 25.2443 56.2721 28.1141 56.0767 30.7467L55.8674 33.2871L55.7557 34.4735C55.515 36.9603 54.4043 39.2822 52.6195 41.0303C50.8346 42.7784 48.49 43.8405 45.9988 44.0294L43.7096 44.2053L41.1972 44.3784L38.5116 44.5375L35.7087 44.6715C33.3074 44.7755 30.9042 44.8295 28.5006 44.8334C26.0971 44.8295 23.6939 44.7755 21.2926 44.6715L18.4897 44.5375L15.8069 44.3784L13.2944 44.2053L10.9997 44.0294C8.50901 43.8399 6.16508 42.7775 4.38077 41.0295C2.59646 39.2814 1.48623 36.9598 1.24561 34.4735L1.13394 33.2871L0.924568 30.7467C0.711934 28.0028 0.598333 25.2521 0.583984 22.5001C0.583984 19.7559 0.729151 16.886 0.924568 14.2535L1.13394 11.7131L1.24561 10.5266C1.48613 8.04079 2.59597 5.71952 4.37971 3.97153C6.16345 2.22353 8.50671 1.16091 10.9969 0.970748L13.2889 0.792081L15.8014 0.62179L18.4869 0.462665L21.2898 0.328665C23.692 0.224611 26.0962 0.170627 28.5006 0.166748ZM22.9173 15.7303V29.2699C22.9173 30.5596 24.3131 31.3636 25.4298 30.7215L37.1548 23.9518C37.41 23.8049 37.6219 23.5934 37.7692 23.3385C37.9166 23.0837 37.9942 22.7945 37.9942 22.5001C37.9942 22.2057 37.9166 21.9165 37.7692 21.6617C37.6219 21.4068 37.41 21.1953 37.1548 21.0484L25.4298 14.2814C25.1751 14.1344 24.8862 14.057 24.592 14.057C24.2979 14.0571 24.009 14.1345 23.7544 14.2817C23.4997 14.4288 23.2883 14.6404 23.1413 14.8952C22.9944 15.15 22.9171 15.439 22.9173 15.7331V15.7303Z"
            fill="#fff"
          />
        </svg>
      );
    case 'arrr':
      return (
        <svg
          width="57"
          height="72"
          viewBox="0 0 57 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M55.0303 39.6515L6.98967 70.831C3.33079 73.2047 -1.3107 69.5832 0.347233 65.6497L12.1421 37.7051C12.578 36.6675 12.578 35.5078 12.1421 34.4702L0.347233 6.52562C-1.31427 2.59215 3.33079 -1.02935 6.98967 1.34429L55.0303 32.5238C57.6566 34.2294 57.6566 37.9459 55.0303 39.6515Z"
            fill="#fff"
            opacity="0.7"
          />
        </svg>
      );
    case 'arrl':
      return (
        <svg
          width="57"
          height="72"
          viewBox="0 0 57 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.96968 39.6515L50.0103 70.831C53.6692 73.2047 58.3107 69.5832 56.6528 65.6497L44.8579 37.7051C44.422 36.6675 44.422 35.5078 44.8579 34.4702L56.6528 6.52562C58.3143 2.59215 53.6692 -1.02935 50.0103 1.34429L1.96968 32.5238C-0.656562 34.2294 -0.656562 37.9459 1.96968 39.6515Z"
            fill="white"
            opacity="0.7"
          />
        </svg>
      );

    default:
      return <></>;
  }
};

export default SvgSelector;
