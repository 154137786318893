import React, { useState } from 'react';
import { Button, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import { H1, P, Wrapper } from 'styles/tags';
import { breakpoints } from 'styles/variables';
import GirlImg from '../../assets/girl.png';
import ButtonBlue from 'components/shared/Buttons/ButtonBlue copy';
import ButtonOrange from 'components/shared/Buttons/ButtonOrange';
import FontStyles from 'styles/fonts';

const About = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText('https://apps.pushkeen.ru/nevsky-runner')
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Через 2 секунды текст кнопки вернется обратно
      });
  };

  return (
    <>
      <FontStyles />
      <Wrapper>
        <Section>
          <AboutContent>
            <AboutText>
              <H1>
                Открой сердце Петербурга <br /> в&nbsp;мобильной игре Nevsky
                Runner!
              </H1>
              <P>
                Следуй маршруту, преодолевая препятствия и&nbsp;открывая новые
                места. Проверь свои знания о&nbsp;Петербурге, отвечая
                на&nbsp;вопросы Сфинкса. Следи за своими достижениями
                и&nbsp;соревнуйся с&nbsp;друзьями за&nbsp;место в&nbsp;рейтинге
                и главный приз сезона. Присоединяйся и&nbsp;начни исследовать
                исторический центр сегодня!
              </P>
            </AboutText>
            <AboutButtons>
              <Social>
                <ButtonBlue
                  href="https://t.me/NevskyRunner"
                  svgName="tg"
                  text="Telegram"
                />
                <ButtonBlue
                  href="https://vk.com/club224959675?w=club224959675"
                  svgName="vk"
                  text="ВКонтакте"
                />
                <ButtonBlue
                  href="https://www.tiktok.com/@nevsky_runner?lang=ru-RU"
                  svgName="tt"
                  text="TikTok"
                />
                <ButtonBlue
                  href="https://www.youtube.com/channel/UCTIdbqp42-IvBWjXjT_mKBw"
                  svgName="yt"
                  text="YouTube"
                />
              </Social>

              {isMobile ? (
                <ButtonOrange
                  href="https://apps.pushkeen.ru/nevsky-runner"
                  svgName=""
                  text="Скачать приложение"
                />
              ) : (
                <ButtonOrange
                  onClick={handleCopyLink}
                  svgName=""
                  text={copied ? 'Ссылка скопирована' : 'Скопировать ссылку'}
                />
              )}
            </AboutButtons>
            <Girl>
              <img src={GirlImg} />
            </Girl>
          </AboutContent>
        </Section>
      </Wrapper>
    </>
  );
};
const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 45px auto;
  @media ${breakpoints.laptop} {
    margin: 3.214vw auto;
  }
  @media ${breakpoints.mobile} {
    margin: 4.688vw auto;
  }
`;

const AboutContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  @media ${breakpoints.laptop} {
    gap: 2.286vw;
  }
  @media ${breakpoints.mobile} {
    max-width: 93.75vw;
    gap: 3.125vw;
  }
`;

const AboutText = styled.div`
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  H1 {
    text-align: center;
  }

  P {
    text-align: center;
  }
  @media ${breakpoints.laptop} {
    margin-bottom: 2.286vw;
    gap: 1.429vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 3.125vw;
    gap: 3.125vw;
  }
`;

const AboutButtons = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  gap: 32px;
  @media ${breakpoints.laptop} {
    gap: 2.286vw;
  }
  @media ${breakpoints.mobile} {
    gap: 3.125vw;
  }
`;

const Social = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-around;
  @media ${breakpoints.laptop} {
    gap: 2.286vw;
  }
  @media ${breakpoints.mobile} {
    flex-wrap: wrap;
    gap: 3.125vw;
  }
`;

const Girl = styled.div`
  position: absolute;
  top: 40%;
  right: -5%;
  transform: rotate(4deg);
  img {
    width: 165px;
    object-fit: cover;
    transform: scaleX(-1);
    @media ${breakpoints.laptop} {
      width: 11.786vw;
    }
  }

  @media (max-width: 450px) {
    display: none;
  }
`;

export default About;
