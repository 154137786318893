import { createGlobalStyle } from "styled-components";
import MTSWide from "../assets/fonts/mts-wide-regular.ttf";

const FontStyles = createGlobalStyle`
  @font-face {
    font-family: 'MTSWide';
    src: url(${MTSWide}) format('truetype');
  }
`;

export default FontStyles;
