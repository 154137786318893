import styled from 'styled-components';
import { breakpoints, colors } from './variables';

export const H1 = styled.h1`
  font-size: 45px;
  font-family: 'MTSWide';
  @media ${breakpoints.laptop} {
    font-size: 3.214vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 4.375vw;
  }
`;

export const H3 = styled.h3`
  font-size: 20px;
  font-family: 'MTSWide';
  @media ${breakpoints.laptop} {
    font-size: 1.429vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 3.125vw;
  }
`;

export const P = styled.p`
  font-size: 20px;
  line-height: 130%;
  font-family: 'Arial';
  @media ${breakpoints.laptop} {
    font-size: 1.429vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 3.75vw;
  }
`;

export const Wrapper = styled.div`
  max-width: 1160px;
  margin: 0 auto;
  @media ${breakpoints.laptop} {
    max-width: 82.857vw;
  }
  @media ${breakpoints.mobile} {
    max-width: 93.75vw;
  }
`;
