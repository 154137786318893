import React from 'react';
import styled from 'styled-components';
import { breakpoints, colors } from 'styles/variables';
import Pushkin from '../../../assets/pushkin.png';
import Boy from '../../../assets/pushkinrun.png';
import { P } from 'styles/tags';

const SlideBeige = () => {
  return (
    <Slide>
      <Name>
        <Char>
          <img src={Boy} />
        </Char>
        <img src={Pushkin} />
      </Name>

      <P>
        С&nbsp;детства увлеченный чтением книг, Пушкин стал придумывать
        собственные истории, развивая писательский талант. Публике нравятся его
        тексты, но&nbsp;из-за своевольных высказываний молодой писатель часто
        попадает в&nbsp;переделки.
      </P>
    </Slide>
  );
};

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
  gap: 150px;
  padding: 250px 0 50px 0;
  background-color: ${colors.beige};
  P {
    text-align: center;
    color: ${colors.white};
    max-width: 650px;
    @media ${breakpoints.laptop} {
      max-width: 46.429vw;
    }
    @media ${breakpoints.mobile} {
      max-width: 90.429vw;
    }
  }
  @media ${breakpoints.laptop} {
    height: 42.857vw;
    gap: 10.714vw;
    padding: 17.857vw 0 3.571vw 0;
  }
  @media ${breakpoints.mobile} {
    height: 125vw;
    gap: 46.875vw;
    padding: 7.813vw 0 21.875vw 0;
  }
`;

const Name = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 960px;
  padding-top: 15px;
  img {
    width: 95%;
  }
  @media ${breakpoints.laptop} {
    width: 68.571vw;
  }
  @media ${breakpoints.mobile} {
    width: 90.25vw;
    height: auto;
    margin-bottom: auto;
  }
`;

const Char = styled.div`
  position: absolute;
  top: -270px;
  left: 100px;
  width: 100%;
  img {
    width: 70%;
    @media ${breakpoints.mobile} {
      width: 125%;
    }
  }
  @media ${breakpoints.laptop} {
    top: -19.286vw;
    left: 7.143vw;
  }
  @media ${breakpoints.mobile} {
    top: 0vw;
    left: -15vw;
  }
`;
export default SlideBeige;
