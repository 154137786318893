import React, { useState } from 'react';
import styled from 'styled-components';
import Kids from '../../assets/kids.gif';
import ButtonWhite from 'components/shared/Buttons/ButtonWhite';
import Pkw from '../../assets/pkw.png';
import Spbbw from '../../assets/spbbw.png';
import ButtonWhiteWide from 'components/shared/Buttons/ButtonWhiteWide';
import { breakpoints } from 'styles/variables';
import SvgSelector from 'components/SvgSelector';
import { useMediaQuery } from '@mui/material';

const Footer = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText('https://apps.pushkeen.ru/nevsky-runner')
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Через 2 секунды текст кнопки вернется обратно
      });
  };

  return (
    <FooterContainer>
      <Wrapper>
        <AboutButtons>
          <Social>
            <ButtonWhite
              href="https://t.me/NevskyRunner"
              svgName="tgw"
              text="Telegram"
            />
            <ButtonWhite
              href="https://vk.com/club224959675?w=club224959675"
              svgName="vkw"
              text="ВКонтакте"
            />
            <ButtonWhite
              href="https://www.tiktok.com/@nevsky_runner?lang=ru-RU"
              svgName="ttw"
              text="TikTok"
            />
            <ButtonWhite
              href="https://www.youtube.com/channel/UCTIdbqp42-IvBWjXjT_mKBw"
              svgName="ytw"
              text="YouTube"
            />
          </Social>

          <SocialMobile>
            <a href="https://t.me/NevskyRunner">
              <SvgSelector name="tgw" />
            </a>

            <a href="https://vk.com/club224959675?w=club224959675">
              <SvgSelector name="vkw" />
            </a>
            <a href="https://www.tiktok.com/@nevsky_runner?lang=ru-RU">
              <SvgSelector name="ttw" />
            </a>
            <a href="https://www.youtube.com/channel/UCTIdbqp42-IvBWjXjT_mKBw">
              <SvgSelector name="ytw" />
            </a>
          </SocialMobile>

          {isMobile ? (
            <ButtonWhiteWide
              href="https://apps.pushkeen.ru/nevsky-runner"
              svgName=""
              text="Скачать приложение"
            />
          ) : (
            <ButtonWhiteWide
              onClick={handleCopyLink}
              svgName=""
              text={copied ? 'Ссылка скопирована' : 'Скопировать ссылку'}
            />
          )}

          <LogoContainer>
            <PkLogo href="https://pushkeen.ru/" target="_blank">
              <img src={Pkw} />
            </PkLogo>
            <SpbbLogo href="https://www.bspb.ru/" target="_blank">
              <img src={Spbbw} />
            </SpbbLogo>
          </LogoContainer>
        </AboutButtons>
        <KidsGif src={Kids} alt="Kids Animation" />
      </Wrapper>
    </FooterContainer>
  );
};
const Wrapper = styled.div`
  position: relative;
  max-width: 1160px;
  margin: 0 auto;
  @media ${breakpoints.laptop} {
    max-width: 82.857vw;
  }
  @media ${breakpoints.mobile} {
    max-width: 93.75vw;
  }
`;
const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 300px;
  padding: 75px 0;
  background: rgb(249, 138, 75);
  background: -moz-linear-gradient(
    76deg,
    rgba(249, 138, 75, 1) 38%,
    rgba(113, 159, 255, 1) 38%
  );
  background: -webkit-linear-gradient(
    76deg,
    rgba(249, 138, 75, 1) 38%,
    rgba(113, 159, 255, 1) 38%
  );
  background: linear-gradient(
    76deg,
    rgba(249, 138, 75, 1) 38%,
    rgba(113, 159, 255, 1) 38%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f98a4b",endColorstr="#719fff",GradientType=1);
  @media ${breakpoints.laptop} {
    height: 21.429vw;
    padding: 5.357vw 0;
  }
  @media ${breakpoints.mobile} {
    height: 60.75vw;
    padding: 15.438vw 0;
  }
`;

const KidsGif = styled.img`
  position: absolute;
  bottom: 0;
  top: 0;
  left: -150px;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  display: block;
  @media ${breakpoints.laptop} {
    display: none;
  }
  @media ${breakpoints.mobile} {
    display: none;
  }
`;

const AboutButtons = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const Social = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  @media (max-width: 450px) {
    display: none;
  }
`;

const SocialMobile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  svg {
    width: 40px;
    @media ${breakpoints.laptop} {
      width: 2.857vw;
    }
    @media ${breakpoints.mobile} {
      width: 10.25vw;
    }
  }
  @media (min-width: 451px) {
    display: none;
  }
  @media ${breakpoints.laptop} {
    gap: 2.857vw;
  }
  @media ${breakpoints.mobile} {
    gap: 10.25vw;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  gap: 40px;
  align-items: center;
  @media ${breakpoints.laptop} {
    gap: 2.857vw;
    bottom: -0.714vw;
  }
  @media ${breakpoints.mobile} {
    left: -7vw;
    gap: 4.5vw;
    bottom: -3.125vw;
  }
`;

const PkLogo = styled.a`
  img {
    width: 250px;
    @media ${breakpoints.laptop} {
      width: 17.857vw;
    }
    @media ${breakpoints.mobile} {
      width: 48.5vw;
    }
  }
`;
const SpbbLogo = styled.a`
  img {
    width: 150px;
    @media ${breakpoints.laptop} {
      width: 10.714vw;
    }
    @media ${breakpoints.mobile} {
      width: 28.5vw;
    }
  }
`;

export default Footer;
