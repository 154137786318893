import SvgSelector from 'components/SvgSelector';
import React from 'react';
import styled from 'styled-components';
import { H3 } from 'styles/tags';
import { breakpoints, colors } from 'styles/variables';

const ButtonOrange = ({ svgName, text, href, onClick }) => {
  return (
    <Button
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
    >
      <SvgSelector name={svgName} />
      <H3>{text}</H3>
    </Button>
  );
};
const Button = styled.a`
  width: 100%;
  width: 300px;
  font-family: 'MTSWide';
  сursor: pointer;
  height: 40px;
  padding: 10px;
  background-color: ${colors.basecolor2Light};
  border-radius: 100px;
  color: ${colors.basecolor2};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 3px solid ${colors.basecolor2};
  h3 {
    white-space: nowrap;
  }

  @media ${breakpoints.laptop} {
    width: 21.429vw;
    height: 2.857vw;
    padding: 0.714vw;
    border-radius: 7.14vw;
    gap: 0.714vw;
    border: 0.214vw solid ${colors.basecolor2};
  }
  @media ${breakpoints.mobile} {
    width: 62.5vw;
    height: 6.5vw;
    padding: 3.125vw 0;
    border-radius: 31.25vw;
    gap: 3.125vw;
    border: 0.938vw solid ${colors.basecolor2};
  }
`;
export default ButtonOrange;
