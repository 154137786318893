import React from 'react';
import styled from 'styled-components';
import Trailer from '../../assets/RunnerTrailer.mp4';
import Logo from '../../assets/rusLogo.png';
import { breakpoints } from 'styles/variables';

const Preview = () => {
  return (
    <Video>
      <Header>
        <img src={Logo} />
      </Header>

      <video
        typeof={'video/mp4'}
        autoPlay={true}
        loop={true}
        muted={true}
        src={Trailer}
        playsInline={true}
      />
    </Video>
  );
};

export default Preview;

const Video = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media ${breakpoints.laptop} {
    height: 62.5vw;
  }
  @media ${breakpoints.mobile} {
    height: 62.5vw;
  }
`;

const Header = styled.div`
  position: absolute;
  top: 0;
  padding: 30px 0 0 100px;
  display: flex;
  align-items: center;
  img {
    width: 175px;
    @media ${breakpoints.laptop} {
      width: 12.5vw;
    }
    @media ${breakpoints.mobile} {
      width: 20vw;
    }
  }
  @media ${breakpoints.laptop} {
    padding: 2.143vw 0 0 7.143vw;
  }
  @media ${breakpoints.mobile} {
    padding: 3.125vw 0 0 3.125vw;
  }
`;
