import SvgSelector from 'components/SvgSelector';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { breakpoints, colors } from '../../../styles/variables';
import { H3 } from 'styles/tags';

const ButtonBlue = ({ svgName, text, href }) => {
  return (
    <Button href={href} target="_blank" rel="noopener noreferrer">
      <SvgSelector name={svgName} />
      <H3>{text}</H3>
    </Button>
  );
};

const Button = styled.a`
  font-family: 'MTSWide';
  width: 200px;
  сursor: pointer;
  height: 40px;
  padding: 10px;
  background-color: ${colors.basecolorLight};
  border-radius: 100px;
  color: ${colors.basecolor};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 3px solid ${colors.basecolor};
  svg {
    width: 40px;
    @media ${breakpoints.laptop} {
      width: 2.857vw;
    }
    @media ${breakpoints.mobile} {
      width: 6.25vw;
    }
  }

  @media ${breakpoints.laptop} {
    width: 14.286vw;
    height: 2.857vw;
    padding: 0.714vw;
    border-radius: 7.143vw;
    gap: 0.714vw;
    border: 0.214vw solid ${colors.basecolor};
  }
  @media ${breakpoints.mobile} {
    width: 35.25vw;
    height: 4.688vw;
    padding: 3.125vw;
    border-radius: 31.25vw;
    gap: 3.125vw;
    border: 0.938vw solid ${colors.basecolor};
  }
`;

export default ButtonBlue;
