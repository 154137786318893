import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay'; // Подключаем стили для автоплей
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import SlideGray from 'components/shared/Slides/SlideGray';
import { H1 } from 'styles/tags';
import styled from 'styled-components';
import { breakpoints, colors } from 'styles/variables';
import SlideOrange from 'components/shared/Slides/SlideOrange';
import SlideBeige from 'components/shared/Slides/SlideBeige';
import SlideGreen from 'components/shared/Slides/SlideGreen';
import SvgSelector from 'components/SvgSelector';

const Slider = () => {
  return (
    <SliderContainer>
      <Swiper
        navigation={{
          nextEl: '.swiper-button-next-custom',
          prevEl: '.swiper-button-prev-custom',
        }}
        autoplay={{ delay: 3000 }}
        loop={true}
        pagination={{ clickable: true }}
        modules={[Navigation, Pagination, Autoplay]} // Добавляем Autoplay
      >
        <H1>Играй за&nbsp;одного из&nbsp;четырех персонажей</H1>
        <SwiperSlide>
          <SlideGray />
        </SwiperSlide>
        <SwiperSlide>
          <SlideOrange />
        </SwiperSlide>
        <SwiperSlide>
          <SlideBeige />
        </SwiperSlide>
        <SwiperSlide>
          <SlideGreen />
        </SwiperSlide>

        <CustomNextButton className="swiper-button-next-custom">
          <SvgSelector name="arrr" />
        </CustomNextButton>
        <CustomPrevButton className="swiper-button-prev-custom">
          <SvgSelector name="arrl" />
        </CustomPrevButton>
      </Swiper>
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 100px;

  .swiper-button-next,
  .swiper-button-prev {
    display: none; // Скрываем стандартные кнопки
  }

  .swiper-button-next-custom {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
    margin-right: 5px;
    svg {
      width: 40px;
      @media ${breakpoints.laptop} {
        width: 2.857vw;
      }
      @media ${breakpoints.mobile} {
        width: 12.5vw;
      }
    }
    @media ${breakpoints.laptop} {
      width: 2.857vw;
      height: 2.857vw;
      margin-right: 0.357vw;
    }
    @media ${breakpoints.mobile} {
      width: 3.125vw;
      height: 3.125vw;
    }
  }

  .swiper-button-prev-custom {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
    margin-left: 10px;
    svg {
      width: 40px;
      @media ${breakpoints.laptop} {
        width: 2.857vw;
      }
      @media ${breakpoints.mobile} {
        width: 12.5vw;
      }
    }
    @media ${breakpoints.laptop} {
      width: 2.857vw;
      height: 2.857vw;
      margin-right: 0.714vw;
    }
    @media ${breakpoints.mobile} {
      width: 3.125vw;
      height: 3.125vw;
    }
  }

  .swiper-button-next-custom {
    right: 10px;
    @media ${breakpoints.laptop} {
      right: 0.714vw;
    }
    @media ${breakpoints.mobile} {
      right: 3.125vw;
    }
  }

  .swiper-button-prev-custom {
    left: 10px;
    @media ${breakpoints.laptop} {
      left: 0.714vw;
    }
    @media ${breakpoints.mobile} {
      left: 3.125vw;
    }
  }

  H1 {
    max-width: 800px;
    position: absolute;
    z-index: 1;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    color: ${colors.white};
    @media ${breakpoints.laptop} {
      max-width: 57.143vw;
    }
    @media ${breakpoints.mobile} {
      display: none;
    }
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 50px;
    @media ${breakpoints.laptop} {
      bottom: 3.571vw;
    }
    @media ${breakpoints.mobile} {
      bottom: 10.625vw;
    }
  }

  .swiper-pagination-bullet {
    background: ${colors.white} !important;
    opacity: 0.5;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    @media ${breakpoints.laptop} {
      width: 0.857vw;
      height: 0.857vw;
      margin: 0 0.4vw;
    }
    @media ${breakpoints.mobile} {
      width: 3.125vw;
      height: 3.125vw;
      margin: 0 1.525vw;
    }
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: ${colors.white} !important;
    width: 16px;
    height: 16px;
    @media ${breakpoints.laptop} {
      width: 1.143vw;
      height: 1.143vw;
    }
    @media ${breakpoints.mobile} {
      width: 3.438vw;
      height: 3.438vw;
    }
  }
`;

const CustomNextButton = styled.div`
  right: 10px;
  @media ${breakpoints.laptop} {
    right: 0.714vw;
  }
  @media ${breakpoints.mobile} {
    display: none;
  }
`;

const CustomPrevButton = styled.div`
  left: 10px;
  @media ${breakpoints.laptop} {
    left: 0.714vw;
  }
  @media ${breakpoints.mobile} {
    display: none;
  }
`;

export default Slider;
