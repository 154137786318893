import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { H1, P, Wrapper } from 'styles/tags';
import { breakpoints } from 'styles/variables';
import S1 from '../../assets/s1.png';
import S2 from '../../assets/s2.png';
import S3 from '../../assets/s3.png';
import S4 from '../../assets/s4.png';
import S5 from '../../assets/s5.png';
import S6 from '../../assets/s6.png';

const Screenshots = () => {
  return (
    <Wrapper>
      <Section>
        <H1>Игровые скриншоты</H1>
        <ScreenshotsContainer>
          <Screenshot>
            <img src={S1} alt="Screenshot 1" />
            <img src={S2} alt="Screenshot 2" />
            <img src={S3} alt="Screenshot 3" />
            <img src={S4} alt="Screenshot 4" />
            <img src={S5} alt="Screenshot 5" />
            <img src={S6} alt="Screenshot 6" />
          </Screenshot>
        </ScreenshotsContainer>
      </Section>
    </Wrapper>
  );
};

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 45px auto;
  @media ${breakpoints.laptop} {
    margin: 3.214vw auto;
  }
  @media ${breakpoints.mobile} {
    margin: 7.813vw auto;
  }
`;

const ScreenshotsContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin: 35px 0;
  @media ${breakpoints.laptop} {
    gap: 2.286vw;
    margin: 3.214vw 0;
  }
  @media ${breakpoints.mobile} {
    gap: 7.813vw;
    margin: 7.813vw 0;
  }
`;

const Screenshot = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 35px;
  img {
    width: 360px;
    border-radius: 20px;
    @media ${breakpoints.laptop} {
      gap: 1.429vw;
      width: 25.714vw;
    }
    @media ${breakpoints.mobile} {
      gap: 6.25vw;
      width: 93.75vw;
    }
  }
  @media ${breakpoints.laptop} {
    gap: 2.286vw;
  }
  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    gap: 7.813vw;
    img:nth-child(n + 4) {
      display: none;
    }
  }
`;

export default Screenshots;
