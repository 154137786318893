import Preview from './components/main/Preview';
import About from './components/main/About';
import RoadMap from './components/main/RoadMap';
import Slider from 'components/main/Slider';
import Screenshots from 'components/main/Screenshots';
import Footer from 'components/main/Footer';

function App() {
  return (
    <>
      <Preview />
      <About />
      <RoadMap />
      <Slider />
      <Screenshots />
      <Footer />
    </>
  );
}

export default App;
