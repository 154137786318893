import React from 'react';
import styled from 'styled-components';
import { breakpoints, colors } from 'styles/variables';
import Maxim from '../../../assets/maxim.png';
import Boy from '../../../assets/boy.png';
import { P } from 'styles/tags';

const SlideGray = () => {
  return (
    <Slide>
      <Name>
        <Char>
          <img src={Boy} />
        </Char>
        <img src={Maxim} />
      </Name>

      <P>
        С&nbsp;позитивным, но&nbsp;легкомысленным Максимом часто случаются
        забавные ситуации. Любитель искусства, он&nbsp;всюду носит с&nbsp;собой
        скетчбук и&nbsp;часто выбирается в&nbsp;исторический центр города
        в&nbsp;поиске вдохновения.
      </P>
    </Slide>
  );
};

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
  gap: 150px;
  padding: 250px 0 50px 0;
  background-color: ${colors.gray};
  P {
    text-align: center;
    color: ${colors.white};
    max-width: 650px;
    @media ${breakpoints.laptop} {
      max-width: 46.429vw;
    }
    @media ${breakpoints.mobile} {
      max-width: 90.429vw;
    }
  }
  @media ${breakpoints.laptop} {
    height: 42.857vw;
    gap: 10.714vw;
    padding: 17.857vw 0 3.571vw 0;
  }
  @media ${breakpoints.mobile} {
    height: 125vw;
    gap: 3.125vw;
    padding: 7.813vw 0 21.875vw 0;
  }
`;

const Name = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 860px;
  img {
    width: 100%;
  }
  @media ${breakpoints.laptop} {
    width: 61.429vw;
  }
  @media ${breakpoints.mobile} {
    width: 81.25vw;
    height: 21.875vw;
    margin-bottom: auto;
  }
`;

const Char = styled.div`
  position: absolute;
  top: -320px;
  left: 70px;
  width: 100%;
  img {
    width: 80%;
    @media ${breakpoints.mobile} {
      width: 130%;
    }
  }
  @media ${breakpoints.laptop} {
    top: -22.857vw;
    left: 5vw;
  }
  @media ${breakpoints.mobile} {
    top: 0vw;
    left: -15vw;
  }
`;

export default SlideGray;
