import React from 'react';
import styled from 'styled-components';
import B1 from '../../assets/b1.png';
import B2 from '../../assets/b2.png';
import B3 from '../../assets/b3.png';
import RoadMapImg from '../../assets/roadmap.png';
import RoadMapImgMini from '../../assets/roadmap_mini.png';
import { P, Wrapper } from 'styles/tags';
import BoyImg from '../../assets/boy.png';
import { breakpoints } from 'styles/variables';

const RoadMap = () => {
  return (
    <Wrapper>
      <Section>
        <Building1>
          <img src={B1} />
          <P>
            Пробегись по&nbsp;Невскому проспекту, преодолевая препятствия,
            собери монеты и&nbsp;открой 13 уникальных достопримечательностей,
            чтобы узнать больше об&nbsp;истории города.
          </P>
        </Building1>
        <Building2>
          <img src={B2} />
          <P>
            В&nbsp;трудный момент воспользуйся предметами-помощниками. Взгляни
            на&nbsp;часы, чтобы выиграть больше времени для преодоления
            препятствий.
          </P>
        </Building2>
        <Building3>
          <P>
            Возьми кошелек, чтобы собрать еще больше монет. Выпей кофе, чтобы
            обрести неуязвимость.
          </P>
          <img src={B3} />
          <P>
            Получай дополнительные награды, правильно отвечая на&nbsp;вопросы
            Сфинкса.
          </P>
        </Building3>

        <RoadMapBackground>
          <img src={RoadMapImg} alt="Road Map" />
        </RoadMapBackground>

        <RoadMapBackgroundMini>
          <img src={RoadMapImgMini} alt="Road Map Mini" />
        </RoadMapBackgroundMini>

        <Boy>
          <img src={BoyImg} />
        </Boy>
      </Section>
    </Wrapper>
  );
};

const Section = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 75px 0;
  margin-bottom: 250px;

  @media ${breakpoints.laptop} {
    padding: 5.357vw 0;
    margin-bottom: 17.857vw;
  }
  @media ${breakpoints.mobile} {
    padding: 23.438vw 0;
    margin-bottom: 0vw;
  }
`;

const Building1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 770px;
  margin-left: auto;
  gap: 35px;
  P {
    margin-left: 200px;
    height: 100%;
    @media ${breakpoints.laptop} {
      margin-left: 14.286vw;
    }
    @media ${breakpoints.mobile} {
      margin-left: 3.5vw;
    }
  }
  img {
    width: 100%;
  }
  @media ${breakpoints.laptop} {
    width: 55vw;
    gap: 2.5vw;
  }
  @media ${breakpoints.mobile} {
    width: 68.75vw;
    gap: 2.5vw;
  }
`;

const Building2 = styled.div`
  display: flex;
  width: 1000px;
  P {
    margin: auto 0;
    margin-left: -120px;
    height: 100%;
    @media ${breakpoints.laptop} {
      margin-left: -8.571vw;
      width: 71.429vw;
    }
    @media ${breakpoints.mobile} {
      width: 79.5vw;
      margin-left: 0vw;
    }
  }
  img {
    width: 600px;
    @media ${breakpoints.laptop} {
      width: 42.857vw;
    }
    @media ${breakpoints.mobile} {
      width: 60.5vw;
      margin-top: -10vw;
    }
  }
  @media ${breakpoints.laptop} {
    width: 71.429vw;
  }
  @media ${breakpoints.mobile} {
    flex-direction: column-reverse;
    width: 62.5vw;
    margin-top: 20vw;
  }
`;
const Building3 = styled.div`
  display: flex;
  flex-direction: column;
  width: 950px;
  margin-left: auto;
  margin-top: 100px;
  P {
    margin-left: 525px;
    @media ${breakpoints.laptop} {
      margin-left: 37.5vw;
    }
    @media ${breakpoints.mobile} {
      margin-left: 0vw;
    }
  }
  P:last-child {
    margin-top: 30px;
    margin-left: 300px;
    margin-right: 275px;
    @media ${breakpoints.laptop} {
      margin-top: 3vw;
      margin-left: 21.429vw;
      margin-right: 19.643vw;
    }
    @media ${breakpoints.mobile} {
      margin-top: 3.125vw;
      margin-left: 0vw;
      margin-right: 0vw;
    }
  }
  img {
    width: 100%;
    @media ${breakpoints.laptop} {
      width: 67.857vw;
      margin-top: 3.143vw;
    }
    @media ${breakpoints.mobile} {
      width: 70.313vw;
      margin-top: 3.143vw;
    }
  }
  @media ${breakpoints.laptop} {
    width: 67.857vw;
    margin-top: 7.143vw;
  }
  @media ${breakpoints.mobile} {
    width: 70.313vw;
    margin-top: 25vw;
  }
`;

const RoadMapBackground = styled.div`
  position: absolute;
  top: 570px;
  right: -20px;
  width: 90%;
  img {
    width: 100%;
  }
  @media ${breakpoints.laptop} {
    top: 40.714vw;
    right: -1.429vw;
  }
  @media (max-width: 450px) {
    display: none;
  }
`;

const RoadMapBackgroundMini = styled.div`
  position: absolute;
  top: 570px;
  right: -20px;

  img {
    width: 100%;
  }
  @media ${breakpoints.laptop} {
    top: 90.875vw;
    right: 0vw;
  }
  @media (min-width: 451px) {
    display: none;
  }
`;

const Boy = styled.div`
  position: absolute;
  top: 200px;
  left: -150px;
  width: 100%;
  width: 600px;
  img {
    width: 100%;
  }
  @media ${breakpoints.laptop} {
    top: 14.286vw;
    left: -10.714vw;
    width: 42.857vw;
  }
  @media ${breakpoints.mobile} {
    top: 25.286vw;
    left: -39.714vw;
    width: 84.375vw;
  }
`;

export default RoadMap;