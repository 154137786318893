import SvgSelector from 'components/SvgSelector';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { breakpoints, colors } from '../../../styles/variables';
import { H3 } from 'styles/tags';

const ButtonWhite = ({ svgName, text, href }) => {
  return (
    <Button href={href} target="_blank" rel="noopener noreferrer">
      <SvgSelector name={svgName} />
      <H3>{text}</H3>
    </Button>
  );
};

const Button = styled.a`
  width: 100%;
  width: 200px;
  font-family: 'MTSWide';
  сursor: pointer;
  height: 40px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 3px solid ${colors.white};
  svg {
    width: 40px;
    @media ${breakpoints.laptop} {
      width: 2.857vw;
    }
    @media ${breakpoints.mobile} {
      width: 6.25vw;
    }
  }
  @media ${breakpoints.laptop} {
    width: 14.286vw;
    height: 2.857vw;
    padding: 0.714vw;
    border-radius: 7.14vw;
    gap: 0.714vw;
    border: 0.214vw solid ${colors.white};
  }
  @media ${breakpoints.mobile} {
    width: 35.714vw;
    width: 200px;
    height: 40px;
    padding: 10px;
    border-radius: 100px;
    gap: 10px;
    border: 0.938vw solid ${colors.white};
  }
`;
export default ButtonWhite;
