import { css } from 'styled-components';

export const variables = `  
  --background-color: #719fff;
	--yellow: #fade66;
	--stroke: #2d3647;
	--black-for-buttons: #2d3647;
	--orange: #eb8304;
	--shadow: rgba(255, 255, 255, 0.15);
	--white: #fff;
`;

export const fontFaces = `  
  @font-face {
  font-family: "MTSWide";
  src:
    url("assets/fonts/mts-wide-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
`;

export const sizes = {
  laptopWidth: '1400px',
  mobileWidth: '480px', //резинка относильно 320px
};

export const breakpoints = {
  laptop: `(max-width: ${sizes.laptopWidth})`,
  mobile: `(max-width: ${sizes.mobileWidth})`,
};

export const fonts = {
  lilita: css`
    font-family: 'Lilita Regular';
    font-style: normal;
    font-weight: 400;
  `,
  nunitoBold: css`
    font-family: 'Nunito Bold';
    font-style: normal;
    font-weight: bold;
  `,
  nunitoExtraBold: css`
    font-family: 'Nunito ExtraBold';
    font-style: normal;
    font-weight: 800;
  `,
};
export const mixins = {
  fs22: css`
    font-size: 22px;
    @media ${breakpoints.laptop} {
      font-size: 1.746vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 6.875vw;
    }
  `,
  fs18: css`
    font-size: 18px;
    @media ${breakpoints.laptop} {
      font-size: 1.429vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 4.375vw;
    }
  `,

  fs58: css`
    font-size: 58px;
    @media ${breakpoints.laptop} {
      font-size: 4.603vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 8.125vw;
    }
  `,
};

export const colors = {
  white: '#fff',
  black: '#000',
  gray: '#909DB7',
  orange:'#CB9A45',
  beige:'#C6B28C',
  green:'#4F714C',
  basecolor: '#719FFF',
  basecolorLight: '#DEE7FA',
  basecolor2: '#F98A4B',
  basecolor2Light: '#F9E3D6',
};
